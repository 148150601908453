import React from "react";

const SunshineSvg = ({style}) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="511.813mm"
        height="277.853mm"
        version="1.1"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 51181 27785"
        style={style}
      >
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            fill="#F9DC7F"
            fillRule="nonzero"
            d="M39477 27785c-170,-4 -304,-152 -291,-322 26,-346 40,-698 40,-1048 0,-7496 -6098,-13594 -13594,-13594 -6870,0 -12672,5135 -13495,11945 -20,164 -169,281 -333,261 -164,-20 -281,-169 -261,-333 860,-7110 6917,-12472 14090,-12472 7826,0 14193,6367 14193,14193 0,365 -14,733 -42,1094 -12,159 -147,280 -306,276zm-18266 -16604l-2209 -8256c-43,-160 52,-324 212,-367 160,-43 324,52 367,212l2209 8256c43,160 -52,324 -212,367 -159,43 -324,-51 -367,-212zm19442 11331c-42,-160 53,-324 213,-366l9940 -2620c160,-42 324,53 366,213 42,160 -53,324 -213,366l-9940 2620c-159,42 -323,-52 -366,-213zm-28997 -3775l-7831 -4593c-143,-84 -190,-267 -107,-410 83,-143 267,-190 410,-107l7831 4593c143,84 190,267 107,410 -84,143 -268,190 -410,107zm21765 -5733c-144,-81 -195,-264 -114,-408l2349 -4169c81,-144 264,-195 408,-114 144,81 195,264 114,408l-2349 4169c-81,144 -265,195 -408,114zm-15995 -196l-5083 -8934c-82,-144 -32,-327 112,-408 144,-82 327,-32 408,112l5083 8934c82,144 32,327 -112,408 -143,81 -326,32 -408,-112zm21699 5961c-81,-144 -31,-327 113,-408l7155 -4046c144,-82 327,-31 408,113 81,144 31,327 -113,408l-7155 4046c-143,81 -326,31 -408,-113zm-28993 3795l-9912 -2723c-159,-44 -253,-209 -209,-368 44,-159 209,-253 368,-209l9912 2723c159,44 253,209 209,368 -44,161 -209,253 -368,209zm19561 -11128c-160,-43 -254,-208 -211,-367l2007 -7402c43,-159 207,-254 367,-211 160,43 254,208 211,367l-2007 7402c-43,160 -209,254 -367,211zm-15601 4015l-4050 -4044c-117,-117 -117,-306 0,-423 117,-117 306,-117 423,0l4050 4044c117,117 117,306 0,423 -116,116 -307,117 -423,0zm22503 -1c-117,-117 -116,-307 1,-423l5663 -5631c117,-117 307,-116 423,1 117,117 116,307 -1,423l-5663 5631c-117,116 -307,115 -423,-1zm4873 11340c-164,1 -298,-132 -299,-297 -1,-165 132,-301 297,-302l6039 -50c162,-1 301,132 302,297 1,165 -132,301 -297,302l-6041 50zm-15912 -15912c-165,0 -299,-134 -299,-299l0 -10279c0,-165 134,-299 299,-299 165,0 299,134 299,299l0 10279c0,165 -134,299 -299,299z"
          />
        </g>
      </svg>
    </div>
  );
};

export default SunshineSvg;
