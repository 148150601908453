 import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    wrapper: {
        padding: "0 5%",
        minHeight: "60vh",
    },

    blogCard: {
        height: "100%",
    },
 
}));
