import React from "react";
import MapChart from "./MapChart";

const index = () => {
  return (
    <div>
      <MapChart />
    </div>
  );
};

export default index;
