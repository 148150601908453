import { createStyles, makeStyles } from "@material-ui/core/styles";


const styles = makeStyles((theme) =>
  createStyles({
    stepTrack: {
      fontSize: 15,
      color: "#999",
    },
  })
);
export default styles;
