import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    video: {

    },
    caption: {
        minHeight: "2rem",
        padding: "2.5%",
        color: "gray",
    },
 
}));
